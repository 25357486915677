<template>
  <div class="modal" :class="{'is-active': opened}">
    <div class="modal-background"></div>
    <div class="modal-card my-modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Create Purchase Order</p>
        <button class="delete" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <p class="title is-4">PO for {{location}} in {{days}} days</p>

        <div class="field">
          <label class="label">Vendor</label>
          <div class="control">
              <div class="select">
                <select v-model="vendorId">
                  <option v-for="(v, i) in vendors" :key="'v-opt-' + i" :value="v.nsId">{{v.entityId + ' ' + v.companyName}}</option>
                </select>
              </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Location</label>
          <div class="control">
            <input class="input is-static my-small-input" type="text" :readonly="true" :value="location + '-VA (' + locationId + ')'">
          </div>
        </div>

        <div class="field">
          <label class="label">Request ship by date</label>
          <div class="control">
            <datepicker v-model="shipByDate" :inline="true" name="shipByDate"></datepicker>
          </div>
        </div>

        <div class="field">
          <label class="label">Memo</label>
          <div class="control">
            <input class="input" type="text" v-model="memo">
          </div>
        </div>

        <div class="field" v-if="items">
          <label class="label"><span>{{items.length}}</span> items</label>
          <div class="control">
            <table class="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Id Index</th>
                  <th>Name</th>
                  <th>Class</th>
                  <th class="has-text-right">Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, j) in items" :key="'item-'+j">
                  <td>{{j+1}}</td>
                  <td>{{item.idIndex}}</td>
                  <td>{{item.nsName}}</td>
                  <td>{{item.className}}</td>
                  <td class="has-text-right">{{item.quantity}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="error" class="notification is-danger is-light">
          <button class="delete" @click="error=''"></button>
          {{error}}
        </div>
      </section>
      <footer class="modal-card-foot">
        <a class="button is-link" :class="{'is-loading': waiting}" @click="createPO" :disabled="!canCreatePO">Create PO</a>
        <a class="button" @click="close()">Close</a>
      </footer>
    </div>
  </div>
</template>
  
<script>
import Datepicker from 'vuejs-datepicker'
import dateFormat from 'dateformat'
  
export default {
  name: 'create-purchase-order-modal',
  components: {
    Datepicker
  },
  props: ['opened', 'vendors', 'location', 'days', 'requestDate', 'items'],
  data () {
    return {
      error: '',
      waiting: false,
      vendorId: null,
      shipByDate: null,
      memo: '',
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
    locationId () {
      if (this.location == 'CA') {
        return 16
      }
      if (this.location == 'NY') {
        return 11
      }
      if (this.location == 'TX') {
        return 24
      }
    },
    canCreatePO () {
      return this.vendorId && this.memo
    }
  },
  watch: {
    opened: function (val) {
      
    },
    days: function (val) {
      if (val) {
        this.shipByDate = new Date(this.requestDate + 'T12:00:00Z')
      }
      this.memo = ''
    },
    location: function (val) {
      this.memo = ''
    },
    vendorId: function (val) {
      this.memo = ''
    },
  },
  methods: {
    reset () {
      this.error = ''
    },
    close (poNum) {
      this.$emit('closeCreatePurchaseOrderModal', poNum)
    },
    createPO () {
      if (!this.canCreatePO) {
        return
      }
      var items = this.items.map(i => ({
        id: i.idIndex,
        quantity: i.quantity,
      }))
      var message = {
        vendorId: this.vendorId,
        locationId: this.locationId,
        shipBy: this.shipByDate.getTime(),
        memo: this.memo,
        items: items,
        currency: 1,
        exchangeRate: 1.0,
        subsidiary: 4,
      }
      this.waiting = true
      this.$http.post(this.server + '/myapp/create-purchase-order/', message).then(resp => {
        this.waiting = false
        this.close(resp.body.tranId)
      }, err => {
        this.error = err.body
        this.waiting = false
      })
    },
  },
}
</script>
  
<style scoped>
.my-modal-card {
  width: 80%!important;
}
.my-small-input {
  width: 200px;
}
</style>